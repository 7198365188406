import React, { useEffect, useState } from "react";
import {
  DataGrid, GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector, GridToolbarFilterButton,
  getGridStringOperators
} from "@mui/x-data-grid";
import styled from "styled-components";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Drawer from "../../components/common/drawer/drawer";
import {
  Typography,
  Button,
  Fab, TextField,
  Divider,
  MenuItem,
  Stack,
  IconButton
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";


import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import UserModal from "../../components/Modals/userModal";
import { Delete, Edit } from "@mui/icons-material";

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 80px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
  
      `}
`;
const DataGridContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  height:${isMobile?"calc(100vh - 302px)":"calc(100vh - 145px)"};
  && .highlighted-row {
    background-color: #ffcccb !important;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? "56px - 70px - 175.23px" : "140.5px - 20px - 52.77px"}
  );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
  overflow-x: ${isMobile
    ? "auto"
    : "hidden"}; /* Add horizontal scroll for mobile */
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1;
  }
`;
const StyledCardHeading = styled(Typography)`
  text-transform: none;
  letter-spacing: 0.5px;
`;



export default function UserManagement(props) {
  const [loading, setLoading] = useState(false);

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [data, setData] = useState([]);
  
  const [rowCount, setRowCount] = useState(0);
const [currentPage, setCurrentPage] = useState(1);
const [paginationModel, setPaginationModel] = useState({
  page: currentPage - 1,
  pageSize: 10,
});
const [searchTerm, setSearchTerm]=useState('')
const optionTerritoryType=[{id:1,name:"T1"},{id:2,name:"T2"}]
const [ytdFilter,setYtdFilter]=useState(false)
const [mtdFilter,setmtdFilter]=useState(false)
const [ftdFilter,setFtdFilter]=useState(false)
const [cumalativeFilter,setCumalativeFilter]=useState(false)
const [startDate,setStartDate]=useState()
const [endDate,setEndDate]=useState()
const [territoryFilter,setTerritoryFilter]=useState(null)
const [territoryType,setTerritoryType]=useState([])
const [territoryOptions,setTerritoryOptions]=useState([])
const [selectedTeritoryType,setSelectedTerritoryType]=useState(null)
const [dateRange1,setDateRange1]=useState(false)
// const [dateRange2,setDateRange2]=useState('')
const [startDate1,setStartDate1]=useState()
const [endDate1,setEndDate1]=useState()
const [minDate,setMinDate]=useState()
const [filter,setFilter]=useState(false)
const [addModal,setAddModal]=useState(false)



// useEffect(() => {
//   // console.log('check page', paginationModel)
//   // if(searchTerm){
//     if(filter){
//     onFilterChange1(searchTerm)}
//   // }

// }, [paginationModel.page]);

useEffect(() => {
  // console.log('check page', paginationModel)
if(!searchTerm&&!startDate&&!endDate&&!territoryFilter){
fetchData()}
  // if(!queryOptions)
  // fetchData(activeTab);
}, [paginationModel.page]);





  const fetchData = async (details) => {
    try {
      setLoading(true);
      setData([]);
   
      let results = await window.Platform.database.getUser({pageNumber:paginationModel.page});
      // console.log("RESPONSE", results);
      // const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

      setData(results?.data)
//       setRowCount(results.count[0].count)
//       const territoryData=results?.region?.map((region,index)=>{
//         let temp={id:index+1,name:region.name,territoryMapping:region.territory_mapping1__c}
//      return temp
//       })
//       console.log("TestData",territoryData)

// const resultMap = {};

// results?.region?.forEach(item => {
//   const territoryMapping = item.name;
//   const subDistrictName = item.sub_district_name__c;

//   if (!resultMap[territoryMapping]) {
//     resultMap[territoryMapping] = [];
//   }

//   resultMap[territoryMapping].push(subDistrictName);
// });

// const resultArray = [];

// for (const territoryMapping in resultMap) {
//   resultArray?.push({
//     territory_mapping1__c: territoryMapping,
//     sub_district_name__c: resultMap[territoryMapping]
//   });
// }

// console.log(resultArray);






//       setTerritoryType(resultArray)
      
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");

    }
  };

  console.log("startDate",startDate)
  console.log("endDate",endDate)
  console.log("endDate",territoryFilter)




        const getColumns1=()=>{
          const stringOperators = getGridStringOperators().filter((op => ['contains'].includes(op.value)));
        let result= [
            {
              field: "sfid",
              headerName: "ID",
              sortable: false,
              width: 250,
              filterable: false,
              // editable: true,
              valueGetter:(params)=>params.row?.id
              ,
              renderCell: (params) => {
                // console.log("PARAMS",params)
                let val = params.row?.id
                ;
                return <Tooltip title={val}>{val}</Tooltip>;

              },
              filterOperators: stringOperators 
              // filterOperators: ['Contains'],
            },
            {
              field: "name",
              headerName: " Name",
              sortable: false,
              width: 250,
              // filterable: false,
              valueGetter:(params)=>params.row?.name,
              renderCell: (params) => {
                let val = params.row?.name||"N/A";
                return <Tooltip title={val}>{val}</Tooltip>;

              },
              filterOperators: stringOperators 
              // filterOperators: ['Contains'],

            },
            {
              field: "mobile",
              headerName: "Mobile Number",
              width: 200,
      // filterable: false, 

              sortable: false,
              valueGetter: params => params.row?.mobile || 'N/A',
              renderCell: params => {
                // console.log(params.row?.container?.id)
                const value = params.row?.mobile || 'N/A'
                return <Tooltip title={value}>{value}</Tooltip>
              },
              filterOperators: stringOperators 
            },
              {
                field: "email",
                headerName: "Email",
                width: 250,
      filterable: false, 

                sortable: false,
                valueGetter: params => params.row?.email|| 'N/A',
                renderCell: params => {
                  // console.log(params.row?.container?.id)
                  const value = params.row?.email|| 'N/A'
                  return <Tooltip title={value}>{value}</Tooltip>
                },
                filterOperators: stringOperators 
              },
                {
                  field: "createddate",
                  headerName: "Created Date",
                  width: 200,
                  sortable: false,
                  filterable: false, 
                  valueGetter: params => {
                      const value =  params.row?.createddate ||"N/A"
                      return value
                  },
                  renderCell: params => {
                    // console.log(params.row.quotation.attributes.shippingOrders.parent.id)
                    const value =  formatDate(params.row.createddate)||"N/A"
                    return <Tooltip title={value}>{value}</Tooltip>
                  },
                  filterOperators: stringOperators,
                  filterable: false, 
              },
           
            {
              field: "profile",
              headerName: "Profile",
              sortable: false,
              width: 200,
      // filterable: false, 

              valueGetter:(params)=>params.row?.profile   ||"N/A",
              renderCell: (params) => {
                let val = params.row?.profile ||"N/A";
                return <Tooltip title={val}>{val}</Tooltip>;

              },
              filterOperators: stringOperators 
            },
            {
              field: "actions",
              headerName: "Actions",
              sortable: false,
              width: 200,
              alignItems: "center",
      // filterable: false, 
              renderCell: (params) => {
                // let val = params.row?.profile ||"N/A";
                return(
                <Stack direction={"row" } >
                 <Tooltip title={"Edit"}><IconButton sx={{color:"#9C27B0FF"}}>
                  <Edit/>
                  </IconButton>
                 </Tooltip>
                 <Tooltip title={"Delete"}><IconButton sx={{color:"red"}} >
                  <Delete/>
                  </IconButton>
                 </Tooltip>

                 </Stack>
              )},
              filterOperators: stringOperators 
            },
          
  ];

    return result;
  };


  function CustomToolbar() {
    return (
      <GridToolbarContainer >
        <GridToolbarColumnsButton   sx={{
         color: '#9C27B0FF'
        }}/>
        <GridToolbarFilterButton sx={{
         color: '#9C27B0FF'
        }}/>
        <GridToolbarDensitySelector sx={{
         color: '#9C27B0FF'
        }}/>
        {/* <GridToolbarExport
          csvOptions={{
            fileName:"FarmerManagement",
          }}
        /> */}
      </GridToolbarContainer>
    );
  }

  const renderContent = () => {
   

   

    return (
      <DataGridContainer>
         <DataGrid
          className="payrollGrid"
        //   checkboxSelection={true}
        density="compact"
          rows={data||[]}
          columns={getColumns1()}
          rowHeight={50}
          disableSelectionOnClick
          disableRowSelectionOnClick
          autoPageSize
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </DataGridContainer>
    );
  };
  function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  console.log("CurrentPage",paginationModel.page)
  function formatDate(dateString) {
    const date = new Date(dateString);
  
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
  
    return `${year}-${month}-${day} , ${hours}:${minutes}:${seconds}`;
  }

const addNewModal=()=>{
    setAddModal(true)
}
  return (
    <>
      {isMobile && <Drawer props={props} />}
      <StaffEditorPageContainer>
         <HeaderContainer>
          <Typography variant="h5">User Management</Typography> 
          {/* <div style={{display:"flex",flexDirection:"row",gap:"20px",paddingLeft:!isMobile&&"15%",marginTop:isMobile&&"5px"}}>
            <Button variant="contained" disabled={ytdFilter||dateRange1||loading} onClick={()=>YTD()}>YTD</Button>
            <Button variant="contained" disabled={mtdFilter ||dateRange1||loading} onClick={()=>MTD()}>MTD</Button>
            <Button variant="contained" disabled={ftdFilter ||dateRange1||loading} onClick={()=>FTD()} >FTD</Button>
            <Button variant="contained" disabled={cumalativeFilter ||dateRange1||loading} onClick={()=>CumulativeFiltefunctionr()}>Cumulative</Button>

          </div>
          {/* <Stack spacing={2} direction={"row"}>
        <Stack direction={"row"} spacing={1}> */}
          {/* <HeaderContainer>
<div style={{width:"100%",display:"flex",flexDirection:"row",gap:isMobile?"10px":"20px"}}>
{/* <Autocomplete
// value={cityVal.find((city) => city.id === deliveryCity) || ''}
//   value={deliveryCity || null}
clearOnBlur={false}
// disableClearable
options={territoryType}
value={selectedTeritoryType}

getOptionLabel={(option) => `${option?.territoryMapping}`}
getOptionValue={(option) => option?.id || ''}
style={{ width: isMobile ? "40%" : "30%"}}
noOptionsText={loading ? "Loading..." : "No option"}
              clearOnEscape={!selectedTeritoryType&&true}
              disabled={loading}
              onChange={async(event, value) => {
                console.log("Autocomplete",value)
                setSelectedTerritoryType(value)
               let filterData=territoryType?.filter(select=>select.territoryMapping===event?.target?.innerText)
               console.log("Autocomplete",filterData)
               setTerritoryOptions(filterData)           
              }}
              onInputChange={(event, value) => {
                // Check if the user's input matches any option
                const matchingOption = territoryType?.find((option) => option.territoryMapping === event?.target?.innerText);

                if (!matchingOption ) {
                    //     // If there's no matching option, set the otherValue to null
                    //   //   setDeliveryCity(null);
                    //   // setSelectedContainer(null);
                    setSelectedTerritoryType(null)
                    setTerritoryOptions('')           
                    setTerritoryFilter(null)
                    
                    return;
                }
            }}
            renderInput={(params) => (
                <TextField
                {...params}
                label="Territory Type"
                variant="outlined"
                
                  
                  
                />
              )}
            />
            <Autocomplete
              // value={cityVal.find((city) => city.id === deliveryCity) || ''}
            //   value={deliveryCity || null}
            clearOnBlur={false}
  // disableClearable
              options={territoryOptions}
              value={territoryFilter}
              getOptionLabel={(option) => `${option?.name}`}
              getOptionValue={(option) => option?.id || ''}
              style={{ width: isMobile ? "40%" : "40%"}}
              noOptionsText={loading ? "Loading..." : "No option"}
              disabled={!territoryOptions||loading}
              onChange={async(event, value) => {
                console.log("valueAuto",value?.name)
                setTerritoryFilter(value?.name)
                fetchTerritoryFilter(value?.name)

               
                
              }}
              onInputChange={(e, value) => {
                const matchingOption = territoryOptions?.find((option) => option?.name === value?.name);

                // Check if the user's input matches any option
                // const matchingOption = autocomplete.find((option) => option.name === value);

                if (!matchingOption ) {
                //   // If there's no matching option, set the otherValue to null
                // //   setDeliveryCity(null);
                // // setSelectedContainer(null);
                setTerritoryFilter(null)
                return;
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Territory"
                  variant="outlined"
                  disabled={!territoryOptions}
                
                  
                  
                />
              )}
            /> */}
             {/* <TextField
          id="outlined-select-currency"
          select
          label={isMobile?"Territory Type":"Select Territory Type"}
          style={{ width: isMobile ? "60%" : "31%"}}
          // defaultValue="EUR"
          // helperText="Please select your currency"
          value={selectedTeritoryType}
          onChange={async(event, value) => {
            console.log("Autocomplete",event?.target?.value)
           setSelectedTerritoryType(event?.target?.value)
           let filterData=territoryType?.filter(select=>select.territory_mapping1__c===event?.target?.value)
           console.log("Autocomplete",filterData)
           setTerritoryOptions(filterData[0].sub_district_name__c) 
           
        }}
        >
          {territoryType?.map((option) => (
            <MenuItem key={option.territory_mapping1__c} value={option.territory_mapping1__c}>
              {option.territory_mapping1__c}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="outlined-select-currency"
          select
          label="Select Territory"
          style={{ width: isMobile ? "60%" : "35%"}}
          // defaultValue="EUR"
          // helperText="Please select your currency"
          value={territoryFilter}
          disabled={!territoryOptions?.length||loading}
          onChange={async(event, value) => {
            console.log("valueAuto",event?.target?.value)
            setTerritoryFilter(event?.target?.value)
            fetchTerritoryFilter(event?.target?.value)

           
            
        }}
        >
          {territoryOptions&&territoryOptions?.map((option) => (
           
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
          
            <Button variant="contained"     disabled={!territoryOptions||loading}   onClick={()=>clearTerritoryFIlter()} >Clear</Button>
            </div>
            <Divider orientation="vertical" variant="middle" flexItem sx={{paddingLeft:"1%"}} />
           
            <div style={{width:"100%",display:"flex",flexDirection:"row",gap:"20px",paddingLeft:!isMobile&&"15%",paddingTop:"4px"}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Start Date" value={startDate1} disabled={ftdFilter||mtdFilter||ytdFilter||loading} format="YYYY/MM/DD" onChange={(data)=>formatDate(data)} />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="End Date"  minDate={minDate} value={endDate1} disabled={ftdFilter||mtdFilter||ytdFilter||!dateRange1||loading} format="YYYY/MM/DD" onChange={(data)=>finalDateRangeFilter(data.$d)} />
            </LocalizationProvider>
            
            <Button variant="contained" onClick={()=>clearDateFilter()} disabled={!dateRange1||loading} >Clear</Button>

            </div>
            
</HeaderContainer>  */}
          {/* </Stack>
          </Stack> */}
            <Button variant="contained" onClick={()=>addNewModal()} sx={{background:"#9C27B0FF", // Background color
    '&:hover': {
      backgroundColor: "#7B1FA2", // Background color on hover
    },}} disabled={loading} >Add New</Button>

          </HeaderContainer> 
        {/* <TableContainer> */}
         
          {loading && <OpaqueLoading />}
          {renderContent()}
        {/* </TableContainer> */}
      {addModal&&<UserModal closeModal={()=>setAddModal(false)} fetchData={()=>fetchData()}/>}
     
      </StaffEditorPageContainer>
    </>
  );
}
