import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Stack,
} from "@mui/material"; // Update import to "@mui/material" for Material-UI v5
import styled from "styled-components";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"; // Update import to "@mui/icons-material" for Material-UI v5
import OpaqueLoading from "../opaqueLoading/opaqueLoading";

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const InputFieldContainer = styled.div`
  margin: 20px 0px;
`;

const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const FirstButton = styled(Button)`
  margin-right: 10px !important;
  position: relative;
  z-index: 10000;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
  position: relative !important;
`;

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const CloseIconButton = styled(IconButton)`
  position: relative;
  z-index: 10000;
`;

const SelectControl = styled(FormControl)`
  .MuiInputLabel-root {
    top: -7px !important;
    left: 14px !important;
  }
`;

const UserModal = (props) => {
  const [loading, setLoading] = useState(false);
const [form,setForm]=useState({});

const [state,setState]=useState([]);
const [selectedState,setSelectedState]=useState(null);
const [district,setDistrict]=useState([]);
const [selectedDistrict,setSelectedDistrict]=useState(null);
const [subDistrict,setSubDistrict]=useState([]);
const [selectedSubDistrict,setSelectedSubDistrict]=useState(null);
const [village,setVillage]=useState([]);
const [selectedVillage,setSelectedVillage]=useState(null);
const [roles,setRoles]=useState([]);
const [selectedRoles,setSelectedRoles]=useState(null);
const [manager,setManager]=useState([]);
const [selectedManager,setSelectedManager]=useState(null);
const [territory,setTerritory]=useState([]);
const [plant,setPlant]=useState([]);
const [selectedPlant,setSelectedPlant]=useState(null);

const [selectedTerritory1,setSelectedTerritory1]=useState(null);
const [selectedTerritory2,setSelectedTerritory2]=useState(null);
const [hrPolicy,setHrPolicy]=useState([]);




 

  useEffect(() => {
    getAllRoles()
    fetchStates();
  }, []);

  const getAllRoles=async()=>{
    try{
  setLoading(true)
      let results = await window.Platform.database.getAllRoles();
      setRoles(results.data)
  setLoading(false)
  
    }
  catch(error){
    console.log(error)
  }
  }

  const getUserAccordingToRoleDashboard=async(data)=>{
    try{
  setLoading(true)
      let results = await window.Platform.database.getUserAccordingToRoleDashboard({name:data});
      setManager(results.data)
  setLoading(false)
  
    }
  catch(error){
    console.log(error)
  }
  }

  const getHrPolicy=async(data)=>{
    try{
  setLoading(true)
      let results = await window.Platform.database.getHrPolicy({name__c:data});
      setHrPolicy(results.data)
  setLoading(false)
  
    }
  catch(error){
    console.log(error)
  }
  }

  const getPlantByState=async(data)=>{
    try{
  setLoading(true)
      let results = await window.Platform.database.getPlantByState({name__c:data});
      setPlant(results.data)
  setLoading(false)
  
    }
  catch(error){
    console.log(error)
  }
  }
  const getTerritoryByRegion=async(data)=>{
    try{
  setLoading(true)
      let results = await window.Platform.database.getTerritoryByRegion({role:data});
      setTerritory(results.data)
  setLoading(false)
  
    }
  catch(error){
    console.log(error)
  }
  }



const fetchStates=async()=>{
  try{
setLoading(true)
    let results = await window.Platform.database.getStateForDashboard();
    setState(results.data)
setLoading(false)

  }
catch(error){
  console.log(error)
}
}

const fetchDistrictByStateId=async(data)=>{
  try{
    setLoading(true)
        let results = await window.Platform.database.getDistrictByStateIdForDashboard({id:data});
        setDistrict(results.data)
    setLoading(false)
    
      }
    catch(error){
      console.log(error)
    }
}

const getSubDistrictByDistrictIdForDashboard=async(data)=>{
  try{
    setLoading(true)
        let results = await window.Platform.database.getSubDistrictByDistrictIdForDashboard({id:data});
        setSubDistrict(results.data)
    setLoading(false)
    
      }
    catch(error){
      console.log(error)
    }
}

const getVillageBySubDistrictIdForDashboard=async(data)=>{
  try{
    setLoading(true)
        let results = await window.Platform.database.getVillageBySubDistrictIdForDashboard({id:data});
        setVillage(results.data)
    setLoading(false)
    
      }
    catch(error){
      console.log(error)
    }
}


const changeHandler = (fieldName, value) => {
  if(fieldName==='mobile'||fieldName==='password'){
    const sanitizedValue = value.replace(/[^0-9]/g, '');
    setForm((prevForm) => ({ ...prevForm, [fieldName]: sanitizedValue }));
  }else{
    setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));

  }
  };

  const validateForm = () => {
    const requiredFields = ['nameC', 'email', 'password', 'active_status', 'employeeType', 'profile', 'manager', 'expenseApprover', 'state', 'district', 'subDistrict', 'village', 'territoryMapping1', 'territoryMapping2', 'plantMaster', 'hrPolicy', 'hrPolicyProfile', 'totalPoints'];
    
    for (let field of requiredFields) {
      if (!form[field] || form[field] === '') {
        return false;
      }
    }
    return true;
  };

  const onClickSave = async () => {
    try {
      if(!validateForm()){
      window.NotificationUtils.showError("Please fill all required fields");
      return 
      }
      setLoading(true);
console.log("FORMM...",form)
let results = await window.Platform.database.createUser({...form});
window.NotificationUtils.showSuccess("User added successfully");

      setLoading(false);
      props.closeModal()
      props.fetchData()

    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error trying to save user");
      setLoading(false);
    }
  };

  const dummyValue=[{name:"Domestic Travel -Sales Representative",id:1},{name:"Domestic Travel -Sales Officer",id:2},{name:"Domestic Travel -AM",id:3}
  ,{name:"Domestic Travel -RM",id:4},{name:"Domestic Travel -ZM",id:5}
  ]

  const renderModalContent = () => {
    return (
        
      <>
        {/* <InputFieldContainer> */}
        
    <div style={{maxHeight:"510px",height:"505px",overflowY:"scroll"}}>
    <Stack direction={"row"} spacing={2} padding={2}>
<strong>Basic Details</strong>

</Stack>
    <Stack direction={"row"} spacing={2} padding={2}>
   
   <TextField label="Name"  required type="text" fullWidth onChange={(e)=>changeHandler("nameC",e.target.value)}/>
   <TextField label="Email" type="text" fullWidth onChange={(e)=>changeHandler("email",e.target.value)}/>

          </Stack>
         
          <Stack direction={"row"} spacing={2} padding={2}>
          <TextField label=" Mobile Number" value={form.mobile||""} type="text" fullWidth onChange={(e)=>changeHandler("mobile",e.target.value)}/>
   <TextField label="Employee ID"  type="text" fullWidth onChange={(e)=>changeHandler("employeeId",e.target.value)}/>
          <TextField label="Password/OTP" value={form.password||""}  required type="text" fullWidth onChange={(e)=>changeHandler("password",e.target.value)}/>
          
          </Stack>
        
          <Stack direction={"row"} spacing={2} padding={2}>
          <FormControl required variant="outlined" style={{ width:"100%" }}>
          <InputLabel style={{ background: 'white' }} >Active</InputLabel>
          <Select
            labelId={`Product`}
            label="School Board"
            
            
            onChange={(e) => changeHandler("active_status",e.target.value)}
          >
           
    <MenuItem value={"Active"}>
      Yes
    </MenuItem>
    <MenuItem value={"Inactive"}>
    No
  </MenuItem>
 
          </Select>
        </FormControl>
             
            
        <FormControl required variant="outlined" style={{ width:"100%" }}>
          <InputLabel style={{ background: 'white' }} >Employee Type</InputLabel>
          <Select
            labelId={`Product`}
            label="School Board"
            
            
            onChange={(e) => changeHandler("employeeType",e.target.value)}
          >
           
    <MenuItem value={"1"}>
      On-Roll
    </MenuItem>
    <MenuItem value={"0"}>
    Off-Roll
  </MenuItem>
 
          </Select>
        </FormControl>

<Autocomplete
            options={roles}
            // getOptionLabel={}
            disabled={loading}

            getOptionLabel={(option) => `${option?.role_name} (${option?.role_id})`}
              getOptionValue={(option) => option || ''}
            style={{ width: "100%" }}
            value={selectedRoles}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={async(event, value) => {
                if(value){
                console.log("TEST1212",value?.role_name)
                await getUserAccordingToRoleDashboard(value?.role_name)
                await getTerritoryByRegion(value?.role_name)
                await getHrPolicy(value?.role_name)


                setSelectedRoles(value)
                changeHandler("profile", value?.role_name)}
                else{
                  setSelectedRoles(null)
                  changeHandler("profile", null)
                }
                // setAssignedEmp(value)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
              
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Profile"}
                required
                variant="outlined"
              />
            )}/>
          </Stack>
<Stack direction={"row"} spacing={2} padding={2}>
<Autocomplete
            options={manager}
            // getOptionLabel={}
            disabled={loading||!selectedRoles}

            getOptionLabel={(option) => `${option?.name} (${option?.id})`}
              getOptionValue={(option) => option?.id || ''}
            style={{ width: "100%" }}
            // value={assignedEmp}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                if(value){
                console.log("TEST1212",value)
                changeHandler("manager", value?.id)
                }
                // setAssignedEmp(value)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
              
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Manager"}
                required
                variant="outlined"
              />
            )}/>
          <Autocomplete
            options={manager}
            // getOptionLabel={}
            disabled={loading||!selectedRoles}

            getOptionLabel={(option) => `${option?.name} (${option?.id})`}
              getOptionValue={(option) => option?.id || ''}
            style={{ width: "100%" }}
            // value={assignedEmp}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                if(value){
                console.log("TEST1212",value)
                changeHandler("expenseApprover", value?.id)
                }
                // setAssignedEmp(value)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
              
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Expense Approver"}
                required
                variant="outlined"
              />
            )}/>
         
             

          </Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
<strong>Territory Details</strong>

</Stack>
        
    <Stack direction={"row"} spacing={2} padding={2}>
          <Autocomplete
            options={state}
            // getOptionLabel={}
            disabled={loading}

            getOptionLabel={(option) => `${option?.name} (${option?.id})`}
              getOptionValue={(option) => option || ''}
            style={{ width: "100%" }}
            value={selectedState}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={async(event, value) => {
                if(value){
                console.log("TEST1212",value)
                await fetchDistrictByStateId(value.id)
                await getPlantByState(value.name)

                setSelectedState(value)
                changeHandler("state", value?.name)
                }
                else{
                setSelectedState(null)
                changeHandler("state", null)

                }
                // setAssignedEmp(value)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
              
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"State"}
                required
                variant="outlined"
              />
            )}/>
             
             <Autocomplete
            options={district}
            // getOptionLabel={}
            disabled={loading||!selectedState}

            getOptionLabel={(option) => `${option?.district_name} (${option?.id})`}
              getOptionValue={(option) => option || ''}
            style={{ width: "100%" }}
            value={selectedDistrict}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={async(event, value) => {
                if(value){
                await getSubDistrictByDistrictIdForDashboard(value.id)
                setSelectedDistrict(value)
                changeHandler("district", value?.district_name)
                }
                else{
                  setSelectedDistrict(null)
                changeHandler("district",null)

                }

                // setAssignedEmp(value)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
              
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"District"}
                required
                variant="outlined"
              />
            )}/>
          
             <Autocomplete
            options={subDistrict}
            // getOptionLabel={}
            disabled={loading||!selectedDistrict}

            getOptionLabel={(option) => `${option?.sub_district_name} (${option?.id})`}
              getOptionValue={(option) => option?.id || ''}
            style={{ width: "100%" }}
            value={selectedSubDistrict}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={async(event, value) => {
                if(value){
                console.log("TEST1212",value)
                await getVillageBySubDistrictIdForDashboard(value.id)
                setSelectedSubDistrict(value)
                changeHandler("subDistrict", value?.sub_district_name)
                }
                else{
                  setSelectedSubDistrict(null)
                changeHandler("subDistrict", null)
                }
                // setAssignedEmp(value)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
              
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Sub District"}
                required
                variant="outlined"
              />
            )}/>
   
    </Stack>

    <Stack direction={"row"} spacing={2} padding={2}>
          
             <Autocomplete
            options={village}
            // getOptionLabel={}
            disabled={loading||!selectedSubDistrict}

            getOptionLabel={(option) => `${option?.village_name} (${option?.id})`}
              getOptionValue={(option) => option?.id || ''}
            style={{ width: "100%" }}
            value={selectedVillage}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                if(value){
                console.log("TEST1212",value)
                setSelectedVillage(value)
                changeHandler("village", value?.village_name)
                }
                else{
                  setSelectedVillage(null)
                changeHandler("village",null)
                }
                // setAssignedEmp(value)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
              
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Village"}
                required
                variant="outlined"
              />
            )}/>
            <Autocomplete
            options={territory}
            // getOptionLabel={}
            disabled={loading||!selectedState}

            getOptionLabel={(option) => `${option?.name} (${option?.id})`}
              getOptionValue={(option) => option?.id || ''}
            style={{ width: "100%" }}
            // value={assignedEmp}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                if(value){
                console.log("TEST1212",value)
                changeHandler("territoryMapping1", value?.id)
                }else{
                changeHandler("territoryMapping1", null)

                }
                // setAssignedEmp(value)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
              
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Territory Mapping 1"}
                required
                variant="outlined"
              />
            )}/>
             <Autocomplete
            options={territory}
            // getOptionLabel={}
            disabled={loading||!selectedState}

            getOptionLabel={(option) => `${option?.name} (${option?.id})`}
              getOptionValue={(option) => option?.id || ''}
            style={{ width: "100%" }}
            // value={assignedEmp}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                if(value){
                console.log("TEST1212",value)
                changeHandler("territoryMapping2", value?.id)
                }
                else{
                changeHandler("territoryMapping2", null)

                }
                // setAssignedEmp(value)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
              
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Territory Mapping 2"}
                required
                variant="outlined"
              />
            )}/>
            
   
    </Stack>
    <Stack direction={"row"} spacing={2} padding={2}>
          <Autocomplete
            options={plant}
            // getOptionLabel={}
            disabled={loading||!selectedState}

            getOptionLabel={(option) => `${option?.name} (${option?.id})`}
              getOptionValue={(option) => option?.id || ''}
            style={{ width: "100%" }}
            // value={assignedEmp}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                if(value){
                console.log("TEST1212",value)
                changeHandler("plantMaster", value?.id)
                }else{
                changeHandler("plantMaster",null)

                }
                // setAssignedEmp(value)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
              
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Plant Master"}
                required
                variant="outlined"
              />
            )}/>
           
     
   
    </Stack>

    <Stack direction={"row"} spacing={2} padding={2}>
<strong>HR Policy Details</strong>

</Stack>

    <Stack direction={"row"} spacing={2} padding={2}>
    <Autocomplete
            options={hrPolicy}
            // getOptionLabel={}
            disabled={loading||!selectedRoles}

            getOptionLabel={(option) => `${option?.name} (${option?.id})`}
              getOptionValue={(option) => option?.id || ''}
            style={{ width: "100%" }}
            // value={assignedEmp}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                if(value){
                console.log("TEST1212",value)
                changeHandler("hrPolicy", value?.id)
                }else{
                changeHandler("hrPolicy", null)

                }
                // setAssignedEmp(value)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
              
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"HR Policy"}
                required
                variant="outlined"
              />
            )}/>
             <Autocomplete
            options={dummyValue}
            // getOptionLabel={}
            disabled={loading||!selectedRoles}

            getOptionLabel={(option) => `${option?.name} (${option?.id})`}
              getOptionValue={(option) => option?.id || ''}
            style={{ width: "100%" }}
            // value={assignedEmp}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                if(value){
                console.log("TEST1212",value)
                changeHandler("hrPolicyProfile", value?.name)
                }else{
                changeHandler("hrPolicyProfile",null)

                }
                // setAssignedEmp(value)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
              
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"HR Policy Profile"}
                required
                variant="outlined"
              />
            )}/>
          <TextField label="Total Points"  type="text" fullWidth onChange={(e)=>changeHandler("totalPoints",e.target.value)}/>

            
   
    </Stack>

        
             
         
     
      
</div>
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',paddingTop:"7px" }}>
<Button variant="contained" onClick={()=>onClickSave()}  sx={{background:"#9C27B0FF", // Background color
    '&:hover': {
      backgroundColor: "#7B1FA2", // Background color on hover
    },}}> Submit</Button>
</div>
      </>
    )
  };

  return (
    <StyledDialog maxWidth="md"  fullWidth={true} open={true} >
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">
            {/* {!isNewDistrict
              ? props.selectedDistrict.districtName || ""
              : "New District"} */}
              Add New User
          </Typography>
          <CloseIconButton
            disabled={loading}
            onClick={() => props.closeModal()}
          >
            <CloseOutlinedIcon />
          </CloseIconButton>
        </ModalHeaderContainer>
        {loading && <OpaqueLoading />}
        {renderModalContent()}
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default UserModal;
