import { useEffect, useState } from "react";
import {
  DataGrid, GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from "@mui/x-data-grid";
import styled from "styled-components";
import { Button, Fab, Tabs, CircularProgress } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { SHIPMENT_TYPES, SO_STATUS } from "../../constants";

import { useParams } from "react-router-dom";

const isMobile = window.innerWidth < 900;

const SHIPPING_ORDERS = [
  {
    id: "1",
    soId: "abcd",
    name:"Distributor visits",
    customer: {
      name: "Customer",
      mobile: "9876543210",
      city: "Kanpur",
    },
    supplier: {
      name: "Supplier",
      wechatNumber: "9876543210",
      deliveryDate: "01 July 2023",
      warehouseContact: "9876543210",
    },
    items: [
      {
        id: "1",
        item: "Item 1",
        category: "Category 1",
        volume: 100,
        qty: 20,
        ctn: 2,
        weight: 200,
        cbm: 50,
        unit: 'kg',
        rate: 20,
        amount: 4000

      }
    ],
    packagingList: {
      id: '1',
      shippingMark: 'abcd',
      chineseDescription: 'chinese description...',
      englishDescription: 'english description...',
      brandLogo: 'url...',
      'pcs/ctn': 23,
      ctns: 23,
      quantity: 20,
      'gw/ctns': '10',
      gw: 10,
      ctnSize: 5,
      cbm: 100,
      photo: 'url...'
    },
    status: SO_STATUS.ON_THE_WAY, 
    shipmentType: SHIPMENT_TYPES.SEA
  },
  {
    id: "2",
    soId: "abcd",
    name:"Retailer Visits",
    customer: {
      name: "Customer",
      mobile: "9876543210",
      city: "Kanpur",
    },
    supplier: {
      name: "Supplier",
      wechatNumber: "9876543210",
      deliveryDate: "01 July 2023",
      warehouseContact: "9876543210",
    },
    items: [
      {
        id: "1",
        item: "Item 1",
        category: "Category 1",
        volume: 100,
        qty: 20,
        ctn: 2,
        weight: 200,
        cbm: 50,
        unit: 'kg',
        rate: 20,
        amount: 4000

      }
    ],
    packagingList: {
      id: '1',
      shippingMark: 'abcd',
      chineseDescription: 'chinese description...',
      englishDescription: 'english description...',
      brandLogo: 'url...',
      'pcs/ctn': 23,
      ctns: 23,
      quantity: 20,
      'gw/ctns': '10',
      gw: 10,
      ctnSize: 5,
      cbm: 100,
      photo: 'url...'
    },
    status: SO_STATUS.ON_THE_WAY, 
    shipmentType: SHIPMENT_TYPES.SEA
  },
  {
    id: "3",
    soId: "abcd",
    name:"Farmer Visits",
    customer: {
      name: "Customer",
      mobile: "9876543210",
      city: "Kanpur",
    },
    supplier: {
      name: "Supplier",
      wechatNumber: "9876543210",
      deliveryDate: "01 July 2023",
      warehouseContact: "9876543210",
    },
    items: [
      {
        id: "1",
        item: "Item 1",
        category: "Category 1",
        volume: 100,
        qty: 20,
        ctn: 2,
        weight: 200,
        cbm: 50,
        unit: 'kg',
        rate: 20,
        amount: 4000

      }
    ],
    packagingList: {
      id: '1',
      shippingMark: 'abcd',
      chineseDescription: 'chinese description...',
      englishDescription: 'english description...',
      brandLogo: 'url...',
      'pcs/ctn': 23,
      ctns: 23,
      quantity: 20,
      'gw/ctns': '10',
      gw: 10,
      ctnSize: 5,
      cbm: 100,
      photo: 'url...'
    },
    status: SO_STATUS.ON_THE_WAY, 
    shipmentType: SHIPMENT_TYPES.SEA
  },
  {
    id: "4",
    soId: "abcd",
    name:"AgriExpert Visits",
    customer: {
      name: "Customer",
      mobile: "9876543210",
      city: "Kanpur",
    },
    supplier: {
      name: "Supplier",
      wechatNumber: "9876543210",
      deliveryDate: "01 July 2023",
      warehouseContact: "9876543210",
    },
    items: [
      {
        id: "1",
        item: "Item 1",
        category: "Category 1",
        volume: 100,
        qty: 20,
        ctn: 2,
        weight: 200,
        cbm: 50,
        unit: 'kg',
        rate: 20,
        amount: 4000

      }
    ],
    packagingList: {
      id: '1',
      shippingMark: 'abcd',
      chineseDescription: 'chinese description...',
      englishDescription: 'english description...',
      brandLogo: 'url...',
      'pcs/ctn': 23,
      ctns: 23,
      quantity: 20,
      'gw/ctns': '10',
      gw: 10,
      ctnSize: 5,
      cbm: 100,
      photo: 'url...'
    },
    status: SO_STATUS.ON_THE_WAY, 
    shipmentType: SHIPMENT_TYPES.SEA
  }
]

const AIR_SHIPPING_ORDERS = [
  {
    id: "1",
    soId: "abcd",
    customer: {
      name: "Customer",
      mobile: "9876543210",
      city: "Kanpur",
    },
    supplier: {
      name: "Supplier",
      wechatNumber: "9876543210",
      deliveryDate: "01 July 2023",
      warehouseContact: "9876543210",
    },
    items: [
      {
        id: "1",
        item: "Item 1",
        category: "Category 1",
        volume: 100,
        qty: 20,
        ctn: 2,
        weight: 200,
        cbm: 50,
        unit: 'kg',
        rate: 20,
        amount: 4000

      }
    ],
    packagingList: {
      id: '1',
      shippingMark: 'abcd',
      chineseDescription: 'chinese description...',
      englishDescription: 'english description...',
      brandLogo: 'url...',
      'pcs/ctn': 23,
      ctns: 23,
      quantity: 20,
      'gw/ctns': '10',
      gw: 10,
      ctnSize: 5,
      cbm: 100,
      photo: 'url...'
    },
    status: SO_STATUS.ON_THE_WAY,
    shipmentType: SHIPMENT_TYPES.AIR
  }
]

const TABS = {
  SEA:0,
  AIR:1
}



const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 90px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? "56px - 20px - 82.23px" : "90px - 20px - 43.77px"}
  );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;
const TabsContainer = styled.div`
  ${isMobile && `
    height: calc(100vh - 56px - 20px - 43.77px);
  `}
`
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;
const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataGridContainer = styled.div`
  height: calc(100vh - 204px);
  overflow-x: auto;
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

// const TabsContainer = styled.div`
//   ${isMobile &&
//   `
//     height: calc(100vh - 56px - 20px);
//   `}
// `;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;
const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function Visits(props) {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [shippingOrders, setShippingOrders] = useState(SHIPPING_ORDERS);
  const [showPackagingListModal, setShowPackagingListModal] = useState();
  const [showItemsModal, setShowItemsModal] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
const [statusInventory, setStatusInventory] = useState(10)


const onRecievedButton = (val) => {

}

const onSentButton = (val) => {

}

useEffect(()=>{
 fetchData()
}, [statusInventory,selectedTab])

const fetchData = async()=> {

  // console.log(statusInventory, selectedTab)
  try{

    // let results = await window.Platform.database.fetchInventory({typeOfShipment: selectedTab, status: statusInventory})
  }
  catch(err){
      console.log(err)
    }


}

  const getInventoryColumns = () => {
    let result
    
     result = [
      {
        field: "name",
        headerName: "Name",
        sortable: false,
        width: 250,

        editable: true,
      },
      {
        field: "target april",
        headerName: "Target april",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.customer?.name || 'N/A',
        renderCell: params => {
          const value = params.row.customer?.name || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }

      },
      {
        field: "achievedApril",
        headerName: "Achieved April",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.customer?.mobile || 'N/A',
        renderCell: params => {
          const value = params.row.customer?.mobile || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "target May",
        headerName: "Target May",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.customer?.name || 'N/A',
        renderCell: params => {
          const value = params.row.customer?.name || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
        
    },
    {
      field: "achievedMay",
      headerName: "Achieved May",
      width: 200,
      sortable: false,
      dataGetter: params => params.row.customer?.mobile || 'N/A',
      renderCell: params => {
        const value = params.row.customer?.mobile || 'N/A'
        return <Tooltip title={value}>{value}</Tooltip>
      }
    },
      {
        field: "target June",
        headerName: "Target June",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.customer?.name || 'N/A',
        renderCell: params => {
          const value = params.row.customer?.name || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }

      },
      {
        field: "achievedJune",
        headerName: "Achieved June",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.customer?.mobile || 'N/A',
        renderCell: params => {
          const value = params.row.customer?.mobile || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      
      {
        field: "target July",
        headerName: "Target July",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.customer?.name || 'N/A',
        renderCell: params => {
          const value = params.row.customer?.name || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }

      },
      {
        field: "achievedJuly",
        headerName: "Achieved July",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.customer?.mobile || 'N/A',
        renderCell: params => {
          const value = params.row.customer?.mobile || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      
      {
        field: "target August",
        headerName: "Target August",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.customer?.name || 'N/A',
        renderCell: params => {
          const value = params.row.customer?.name || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }

      },
      {
        field: "achievedAugust",
        headerName: "Achieved August",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.customer?.mobile || 'N/A',
        renderCell: params => {
          const value = params.row.customer?.mobile || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
     
      {
        field: "target September",
        headerName: "Target September",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.customer?.name || 'N/A',
        renderCell: params => {
          const value = params.row.customer?.name || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }

      },
      {
        field: "achievedSeptember",
        headerName: "Achieved September",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.customer?.mobile || 'N/A',
        renderCell: params => {
          const value = params.row.customer?.mobile || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "target October",
        headerName: "Target October",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.customer?.name || 'N/A',
        renderCell: params => {
          const value = params.row.customer?.name || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }

      },
      {
        field: "achievedOctober",
        headerName: "Achieved October",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.customer?.mobile || 'N/A',
        renderCell: params => {
          const value = params.row.customer?.mobile || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "target December",
        headerName: "Target December",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.customer?.name || 'N/A',
        renderCell: params => {
          const value = params.row.customer?.name || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }

      },
      {
        field: "achievedDecember",
        headerName: "Achieved December",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.customer?.mobile || 'N/A',
        renderCell: params => {
          const value = params.row.customer?.mobile || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "target January",
        headerName: "Target January",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.customer?.name || 'N/A',
        renderCell: params => {
          const value = params.row.customer?.name || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }

      },
      {
        field: "achievedJanuary",
        headerName: "Achieved January",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.customer?.mobile || 'N/A',
        renderCell: params => {
          const value = params.row.customer?.mobile || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "target February",
        headerName: "Target February",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.customer?.name || 'N/A',
        renderCell: params => {
          const value = params.row.customer?.name || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }

      },
      {
        field: "achievedFeb",
        headerName: "Achieved February",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.customer?.mobile || 'N/A',
        renderCell: params => {
          const value = params.row.customer?.mobile || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "target March",
        headerName: "Target March",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.customer?.name || 'N/A',
        renderCell: params => {
          const value = params.row.customer?.name || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }

      },
      {
        field: "achievedMarch",
        headerName: "Achieved March",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.customer?.mobile || 'N/A',
        renderCell: params => {
          const value = params.row.customer?.mobile || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      
     
      
  ]


    return result
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "Warehouse inventory",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const handleTabChange = async (value) => {
    setShippingOrders([]);
    setSelectedTab(value);
    // if(value === TABS.SEA){
      setShippingOrders(SHIPPING_ORDERS)
    // }
    // else if(value === TABS.AIR)
    // {
    //   setShippingOrders(AIR_SHIPPING_ORDERS)
    // }
  }

  const renderLoading = () => {
    return(
      <LoadingContainer>
        <CircularProgress color='primary'/>
      </LoadingContainer>
    )
  }

  const renderTabContent = () => {
    if(loading){
      return renderLoading();
    }
    else
    return (
      <DataGridContainer>
        <DataGrid
          className="payrollGrid"
          checkboxSelection={false}
          rows={SHIPPING_ORDERS}
          columns={getInventoryColumns()}
        //   rowHeight={70}
        density="compact"
          disableSelectionOnClick
          disableRowSelectionOnClick
          autoPageSize
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </DataGridContainer>
    );
  };

  const renderModals = () => {
    return <>
      {/* {showItemsModal && <InventoryItemsModal
        onClose={() => setShowItemsModal(null)}
        items={showItemsModal}
      />}
      {showPackagingListModal && <PackagingListModal
        onClose={() => setShowPackagingListModal(null)}
        list={showPackagingListModal}
      />} */}
    </>
  }

  return (
    <>
     
        
            {renderTabContent()}
         

    </>
  );
}
